import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  fetchFunc: null,
  sizeKey: null,
  componentFilters: null,
  showPagination: false,
  rowItemCount: null,
}

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPaginationData: (state, action) => {
      return Object.assign({}, state, action.payload)
    },
  },
})

export const { setPaginationData } = paginationSlice.actions
export default paginationSlice.reducer
